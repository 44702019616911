<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Sobre nosotros',

    metaInfo: {
      title: 'Sobre nosotros',
      meta: [
        { name: 'description', content: 'Cantdelimar es una empresa de Cantabria dedicada a la fabricación de conservas de pescado, principalmente pudin de pescado. Desde 1996, esta empresa elabora pastel de pez de roca, txangurro y otros productos en conserva y semi conserva.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'about-our-product',
        'theme-features',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'sobre nosotros',
      },
    },
  }
</script>
